import { mapActions, mapGetters } from 'vuex';
import BaseMonthPicker from '@/components/inputs/BaseMonthPicker';
import ExpenseStats from '@/components/expense/ExpenseStats';
import SummaryBarChart from '@/components/charts/SummaryBarChart';

import { categoriesData } from '@/mixins/categoriesMixin';
import monthPickerMixin from '@/mixins/monthPickerMixin';
import expenseVewMixin from '@/mixins/expenseVewMixin';

export default {
    components: {
        BaseMonthPicker,
        ExpenseStats,
        SummaryBarChart,
    },

    mixins: [categoriesData, monthPickerMixin, expenseVewMixin],

    computed: {
        ...mapGetters(['expensesStats', 'expenseSumChartData']),
    },

    methods: {
        ...mapActions(['getExpensesSum']),

        fetchExpensesSum() {
            this.getExpensesSum({
                category_id: this.selectedCategory,
                startDate: this.startMonth,
                endDate: this.endMonth,
                excludeCategories: this.excludedCategories,
            });
        },
    },

    watch: {
        startMonth() {
            if (this.expensesSumLoaded) {
                this.fetchExpensesSum();
            }
        },

        endMonth() {
            if (this.expensesSumLoaded) {
                this.fetchExpensesSum();
            }
        },
    },
};
