<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="4">
        <BaseCategorySelector :value.sync="selectedCategory" />
      </v-col>
      <v-col cols="12" lg="4">
        <BaseMonthPicker
          :value.sync="startMonthYear"
          :label="$t('startMonth')"
          :showMonthPicker.sync="showStartMonthPicker"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <BaseMonthPicker
          :value.sync="endMonthYear"
          :label="$t('endMonth')"
          :showMonthPicker.sync="showEndMonthPicker"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="8">
        <v-card class="pt-6 pb-4">
          <SummaryBarChart
            :options="barChartOptions"
            :chartData="expenseSumChartData"
          />
        </v-card>
      </v-col>
      <v-col cols="12" lg="4">
        <ExpenseStats :stats="expensesStats" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseCategorySelector from '@/components/inputs/BaseCategorySelector';
import exploreViewMixin from '@/mixins/exploreViewMixin';

export default {
  components: {
    BaseCategorySelector,
  },

  mixins: [exploreViewMixin],

  data: () => ({
    selectedCategory: null,
  }),

  created() {
    if (this.categoriesData.length) {
      this.selectedCategory = this.categoriesData[0].id;
    }

    if (!this.categoriesData.length) {
      this.getCategoriesData().then(() => {
        this.selectedCategory = this.categoriesData[0].id;
        this.fetchExpensesSum();
      });
    }
  },

  watch: {
    selectedCategory() {
      this.fetchExpensesSum();
    },
  },
};
</script>
