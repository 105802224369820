<template>
  <v-select
    :label="$t('category')"
    :items="categoriesData"
    item-text="name"
    item-value="id"
    v-model="category"
  ></v-select>
</template>

<script>
import { categoriesData } from '@/mixins/categoriesMixin';

export default {
  props: {
    value: Number,
  },

  mixins: [categoriesData],

  computed: {
    category: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      },
    },
  },
};
</script>
