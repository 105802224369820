export default {
  data: () => ({
    showMonthPicker: false,
    showStartMonthPicker: false,
    showEndMonthPicker: false,
  }),

  created() {
    if (!this.monthYear) this.setMonthYear('monthYear', new Date());
    if (!this.startMonthYear) {
      const startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1);
      this.setMonthYear('startMonthYear', startDate);
    }
    if (!this.endMonthYear) this.setMonthYear('endMonthYear', new Date());
  },

  computed: {
    monthYear: {
      get() {
        return this.$store.state.menu.monthYear;
      },
      set(value) {
        this.$store.commit('setMonthYear', value);
      },
    },

    startMonthYear: {
      get() {
        return this.$store.state.menu.startMonthYear;
      },
      set(value) {
        this.$store.commit('setStartMonthYear', value);
      },
    },

    endMonthYear: {
      get() {
        return this.$store.state.menu.endMonthYear;
      },
      set(value) {
        this.$store.commit('setEndMonthYear', value);
      },
    },

    expenseMonth() {
      return {
        year: parseInt(this.monthYear.substring(0, 4)),
        month: parseInt(this.monthYear.substring(5)),
      };
    },

    startMonth() {
      if (this.startMonthYear) {
        return {
          year: parseInt(this.startMonthYear.substring(0, 4)),
          month: parseInt(this.startMonthYear.substring(5)),
        };
      }
    },

    endMonth() {
      if (this.endMonthYear) {
        return {
          year: parseInt(this.endMonthYear.substring(0, 4)),
          month: parseInt(this.endMonthYear.substring(5)),
        };
      }
    },
  },

  methods: {
    setMonthYear(variable, date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const fullMonth = month < 10 ? `0${month}` : `${month}`;

      this[variable] = `${year}-${fullMonth}`;
    },
  },
};
