<template>
  <v-card class="d-flex flex-column justify-center" height="100%">
    <div class="align-self-center">
      <v-card-title class="text-xl-h4 text-lg-h5">
        {{ $t('sumExpenses') }}: {{ stats.sumExpenses | currency }}
        {{ $t('currency') }}
      </v-card-title>
      <v-card-title class="text-xl-h4 text-lg-h5">
        {{ $t('averageExpense') }}: {{ stats.avgExpenses | currency }}
        {{ $t('currency') }}
      </v-card-title>
      <v-card-title class="text-xl-h4 text-lg-h5">
        {{ $t('maxExpense') }}: {{ stats.maxExpenses | currency }}
        {{ $t('currency') }}
      </v-card-title>
      <v-card-title class="text-xl-h4 text-lg-h5">
        {{ $t('minExpense') }}: {{ stats.minExpenses | currency }}
        {{ $t('currency') }}
      </v-card-title>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    stats: Object,
  },
};
</script>
