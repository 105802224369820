<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="date"
        :label="label"
        :prepend-icon="mdiCalendar"
        readonly
        v-on="on"
        hide-details
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      type="month"
      :locale="locale"
      @input="open = false"
      no-title
      scrollable
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mdiCalendar } from '@mdi/js';

export default {
  props: {
    label: String,
    value: String,
    showMonthPicker: Boolean,
  },

  data: () => ({
    locale: 'hr-HR',
    mdiCalendar,
  }),

  computed: {
    open: {
      get() {
        return this.showMonthPicker;
      },
      set(value) {
        this.$emit('update:showMonthPicker', value);
      },
    },
    date: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      },
    },
  },
};
</script>

<style scoped>
input {
  text-align: center;
}
</style>
