<template>
  <canvas ref="chart" id="myChart" width="400" height="400"></canvas>
</template>

<script>
import {
  Chart,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  LineController,
  CategoryScale,
  LinearScale,
  Tooltip,
} from 'chart.js';

Chart.register(
  LineElement,
  BarElement,
  PointElement,
  BarController,
  LineController,
  CategoryScale,
  LinearScale,
  Tooltip,
);

export default {
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    chart: null,
  }),

  methods: {
    createChart() {
      const ctx = this.$refs.chart.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: this.chartData,
        options: {
          normalized: true,
          plugins: {
            legend: this.options.legend,
          },
          scales: this.options.scales,
          responive: this.options.responsive,
          maintainAspectRatio: this.options.maintainAspectRatio,
        },
      });
    },
  },

  watch: {
    chartData(newData, _) {
      if (!this.chart) this.createChart();
      if (!this.$store.state.limits.limitsLoaded) return;

      this.chart.data.datasets.forEach((_, index) => {
        this.chart.data.datasets[index].data = newData.datasets[index].data;
      });

      this.chart.data.labels = newData.labels;

      this.chart.update();
    },
  },
};
</script>
