import { mapActions, mapState } from 'vuex';

const categoriesData = {
  computed: {
    ...mapState({
      categoriesData: (state) => state.categories.categoriesData,
    }),
  },

  methods: {
    ...mapActions(['getCategoriesData']),
  },
};

export { categoriesData };
