import { mapState } from 'vuex';

export default {
    data: () => ({
        barChartOptions: {
            repsonsive: true,
            maintainAspectRatio: false,
            scales: {
                x: { grid: { display: false } },
                y: { grid: { display: false } },
            },
            legend: {
                display: false,
            },
        },
    }),

    computed: {
        ...mapState({
            expensesLoaded: (state) => state.expenses.expensesLoaded,
        }),
    },
};
